<template>
  <div class="app-footer-wrapper">
    <div class="footer-inner-wrapper">
      <div class="footer-up-container">
        <div class="logo-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="109"
            height="19"
            viewBox="0 0 109 19"
            fill="none"
          >
            <path
              d="M93.3611 7.76683H105.462V0H108.908V19.0062H105.462V11.2394H93.3611V19.0062H89.915V0H93.3611V7.76683Z"
              fill="white"
            />
            <path
              d="M86.6281 19.0062H74.1959C73.8778 19.0062 73.5332 18.9709 73.162 18.9002C72.7998 18.8206 72.4375 18.7014 72.0752 18.5423C71.7218 18.3833 71.3816 18.18 71.0547 17.9326C70.7277 17.6764 70.4361 17.3715 70.1799 17.0181C69.9325 16.6558 69.7337 16.2405 69.5835 15.7722C69.4333 15.2951 69.3582 14.7605 69.3582 14.1685V4.8377C69.3582 4.5196 69.3935 4.17942 69.4642 3.81714C69.5437 3.44603 69.663 3.08376 69.822 2.73032C69.9811 2.36804 70.1887 2.02344 70.445 1.69651C70.7012 1.36958 71.0061 1.08241 71.3595 0.835C71.7218 0.578757 72.1371 0.375529 72.6054 0.225318C73.0737 0.0751058 73.6038 0 74.1959 0H86.6281V3.44603H74.1959C73.7452 3.44603 73.4006 3.56532 73.162 3.80389C72.9235 4.04246 72.8042 4.3959 72.8042 4.86421V14.1685C72.8042 14.6103 72.9235 14.9549 73.162 15.2023C73.4095 15.4409 73.7541 15.5602 74.1959 15.5602H86.6281V19.0062Z"
              fill="white"
            />
            <path
              d="M64.8517 11.2394H53.9702V7.76683H64.8517V11.2394ZM66.4024 19.0062H53.9702C53.4931 19.0062 52.9717 18.9223 52.4062 18.7544C51.8407 18.5865 51.315 18.3126 50.829 17.9326C50.3519 17.5438 49.9498 17.0446 49.6229 16.4349C49.3048 15.8164 49.1458 15.0609 49.1458 14.1685V1.73627C49.1458 1.4977 49.1899 1.27238 49.2783 1.06032C49.3667 0.848254 49.4859 0.662699 49.6361 0.503651C49.7952 0.344603 49.9808 0.2209 50.1928 0.13254C50.4049 0.0441799 50.6346 0 50.882 0H66.4024V3.44603H52.5918V14.1685C52.5918 14.6191 52.7111 14.9637 52.9496 15.2023C53.1882 15.4409 53.5372 15.5602 53.9967 15.5602H66.4024V19.0062Z"
              fill="white"
            />
            <path
              d="M46.6806 3.44603H39.086V19.0062H35.64V3.44603H28.0322V0H46.6806V3.44603Z"
              fill="white"
            />
            <path
              d="M25.4744 19.0062H22.0283V0H25.4744V19.0062Z"
              fill="white"
            />
            <path
              d="M18.9929 14.8312C18.9929 15.2553 18.9178 15.7104 18.7676 16.1964C18.6262 16.6823 18.3877 17.133 18.0519 17.5483C17.725 17.9635 17.2964 18.3126 16.7663 18.5953C16.2361 18.8692 15.5867 19.0062 14.8179 19.0062H1.73627C1.48886 19.0062 1.25913 18.962 1.04706 18.8737C0.835 18.7853 0.649445 18.666 0.490397 18.5158C0.340185 18.3568 0.2209 18.1712 0.13254 17.9591C0.0441799 17.7471 0 17.5173 0 17.2699V1.73627C0 1.4977 0.0441799 1.27238 0.13254 1.06032C0.2209 0.848254 0.340185 0.662699 0.490397 0.503651C0.649445 0.344603 0.835 0.2209 1.04706 0.13254C1.25913 0.0441799 1.48886 0 1.73627 0H13.2672C13.6914 0 14.1464 0.0751058 14.6324 0.225318C15.1184 0.375529 15.569 0.618519 15.9843 0.954286C16.4084 1.28122 16.7574 1.70976 17.0314 2.23992C17.3141 2.77008 17.4555 3.41952 17.4555 4.18825V4.8377C17.4555 5.44738 17.3539 6.10566 17.1506 6.81254C16.9474 7.51058 16.6205 8.17328 16.1698 8.80064C16.5586 9.03921 16.9209 9.32638 17.2567 9.66214C17.6013 9.99791 17.9017 10.3867 18.1579 10.8285C18.4142 11.2703 18.6174 11.7695 18.7676 12.3262C18.9178 12.8829 18.9929 13.497 18.9929 14.1685V14.8312ZM15.5469 14.1685C15.5469 13.7179 15.4762 13.3158 15.3348 12.9624C15.1935 12.6001 14.9947 12.2908 14.7384 12.0346C14.4822 11.7784 14.1729 11.584 13.8106 11.4514C13.4484 11.3101 13.0419 11.2394 12.5913 11.2394H4.82445V7.76683H11.0406C11.4912 7.76683 11.8976 7.70056 12.2599 7.56802C12.6222 7.42664 12.9315 7.22783 13.1877 6.97159C13.4439 6.71535 13.6383 6.4105 13.7709 6.05707C13.9123 5.69479 13.9829 5.28833 13.9829 4.8377V4.18825C13.9829 3.69344 13.7444 3.44603 13.2672 3.44603H3.44603V15.5602H14.8179C14.8798 15.5602 14.9549 15.5557 15.0433 15.5469C15.1316 15.5381 15.2111 15.5116 15.2818 15.4674C15.3525 15.4232 15.4144 15.3481 15.4674 15.2421C15.5204 15.136 15.5469 14.9902 15.5469 14.8047V14.1685Z"
              fill="white"
            />
          </svg>
        </div>
        <div class="address-container">
          <div class="icon-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <g clip-path="url(#clip0_161_482)">
                <path
                  d="M6.0002 7.13451C4.7882 7.13451 3.8042 6.18051 3.8042 5.00451C3.8042 3.82851 4.7882 2.87451 6.0002 2.87451C7.2122 2.87451 8.1962 3.82851 8.1962 5.00451C8.1962 6.18051 7.2122 7.13451 6.0002 7.13451ZM6.0002 3.62451C5.2202 3.62451 4.5782 4.24851 4.5782 5.00451C4.5782 5.76051 5.2142 6.38451 6.0002 6.38451C6.7862 6.38451 7.4222 5.76051 7.4222 5.00451C7.4222 4.24851 6.7802 3.62451 6.0002 3.62451Z"
                  fill="white"
                />
                <path
                  d="M6.0002 11.9043C5.9222 11.9043 5.8562 11.8863 5.7842 11.8443C5.5862 11.7183 0.960205 8.68226 0.960205 5.01026C0.960205 2.31626 3.22221 0.114258 6.0002 0.114258C8.7782 0.114258 11.0402 2.30426 11.0402 5.01026C11.0402 8.63426 6.4142 11.7183 6.2162 11.8443C6.1562 11.8743 6.0782 11.9043 6.0002 11.9043ZM6.0002 0.858258C3.64821 0.858258 1.73421 2.71226 1.73421 4.99826C1.73421 7.84826 5.1062 10.4223 6.0002 11.0583C6.8942 10.4163 10.2662 7.80626 10.2662 4.99826C10.2662 2.71826 8.3522 0.858258 6.0002 0.858258Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_161_482">
                  <rect
                    width="12"
                    height="12"
                    fill="white"
                    transform="translate(0 0.00634766)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="address-text">{{ `350 ORCHARD ROAD, #18-04, SHAW HOUSE, SINGAPORE 238868` }}</div>
        </div>
      </div>
      <div class="footer-down-container">
        <div class="website-registration">
          © 2024 BITECH All Rights Reserved
        </div>
        <div class="right-community-wrapper">
          <a href="/" class="icon-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M1.9903 1.80762C3.5803 1.81262 5.17101 1.8069 6.76101 1.81047C8.14101 3.81262 9.51387 5.82047 10.896 7.8219C12.6289 5.82405 14.3453 3.81047 16.0746 1.80976C16.5439 1.81047 17.0132 1.80762 17.4824 1.81119C15.5039 4.12476 13.5074 6.42262 11.5324 8.73905C13.6824 11.8983 15.8574 15.039 18.0182 18.1912C16.4289 18.1919 14.8403 18.189 13.251 18.1926C11.7832 16.074 10.331 13.9462 8.86958 11.824C7.05244 13.9498 5.21601 16.0598 3.40315 18.189C2.92958 18.1948 2.45673 18.1898 1.98315 18.1912C4.06744 15.7619 6.15601 13.3376 8.24387 10.9126C6.15887 7.87833 4.07101 4.84547 1.9903 1.80762ZM3.90458 2.86119C7.23958 7.6319 10.5696 12.4062 13.9132 17.1719C14.636 17.1698 15.3589 17.1726 16.0817 17.1705C12.7417 12.4026 9.41244 7.62833 6.07244 2.86047C5.34958 2.85905 4.62744 2.85762 3.90458 2.86119Z"
                fill="white"
              />
            </svg>
          </a>
          <a href="/" class="icon-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M18.2231 5.88135C18.0356 5.13135 17.4463 4.56885 16.6963 4.35456C15.357 4.00635 9.9999 4.00635 9.9999 4.00635C9.9999 4.00635 4.64275 4.00635 3.30347 4.35456C2.55347 4.54206 1.99097 5.13135 1.77668 5.88135C1.42847 7.22063 1.42847 10.0063 1.42847 10.0063C1.42847 10.0063 1.42847 12.7921 1.77668 14.1313C1.96418 14.8813 2.55347 15.4438 3.30347 15.6581C4.64275 16.0063 9.9999 16.0063 9.9999 16.0063C9.9999 16.0063 15.357 16.0063 16.6963 15.6581C17.4463 15.4706 18.0088 14.8813 18.2231 14.1313C18.5713 12.7921 18.5713 10.0063 18.5713 10.0063C18.5713 10.0063 18.5713 7.22063 18.2231 5.88135ZM8.28561 12.5778V7.43492L12.732 10.0063L8.28561 12.5778Z"
                fill="white"
              />
            </svg>
          </a>
          <a href="/" class="icon-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M13.7913 2.63281H7.33682C4.96136 2.63281 3 4.55508 3 6.96962V13.4241C3 15.7996 4.92228 17.7687 7.34463 17.7687H13.7991C16.1746 17.7687 18.1437 15.8465 18.1437 13.4241V6.96962C18.0968 4.55508 16.1746 2.63281 13.7913 2.63281ZM10.5875 14.0336C7.55143 14.0336 5.32653 10.5709 7.68103 7.34766C7.71898 7.29578 7.7649 7.25022 7.81708 7.21268C11.0335 4.90131 14.4789 7.10269 14.4789 10.1422C14.4789 12.2988 12.7364 14.0336 10.5875 14.0336ZM14.5883 7.00872C14.096 7.00872 13.6819 6.59455 13.6819 6.10229C13.6819 5.60999 14.096 5.19587 14.5883 5.19587C15.0806 5.19587 15.4947 5.60999 15.4947 6.10229C15.4947 6.59454 15.0806 7.00872 14.5883 7.00872ZM10.5875 7.64949C9.18878 7.64949 8.09481 8.78253 8.09481 10.1422C8.09481 11.5409 9.22786 12.6348 10.5875 12.6348C11.9862 12.6348 13.0802 11.5018 13.0802 10.1422C13.0802 8.78253 11.9472 7.64949 10.5875 7.64949Z"
                fill="white"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style lang="scss" scoped>
.app-footer-wrapper {
  background: #398cfe;
  .footer-inner-wrapper {
    height: 221px;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    @media screen and (max-width: 1280px) {
      padding-left: 30px;
      padding-right: 30px;
    }
    .footer-up-container {
      height: 117px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding-top: 30px;
      padding-bottom: 30px;
      .logo-container {
        height: 19px;
        width: 108px;
        overflow: hidden;
      }
      .address-container {
        margin-top: 24px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        .icon-wrapper {
          width: 12px;
          height: 12px;
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        }
        .address-text {
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          line-height: 14px;
        }
      }
    }
    .footer-down-container {
      height: 104px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
      .website-registration {
        height: 14px;
        line-height: 14px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
      }
      .right-community-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-flow: column;
        align-items: center;
        gap: 8px;
        .icon-wrapper {
          display: block;
          width: 24px;
          height: 24px;
          flex-shrink: 0;
          cursor: pointer;
          border-radius: 4px;
          background: rgba(199, 206, 214, 0.2);
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            background: rgba(199, 206, 214, 0.5);
          }
        }
      }
    }
  }
}
</style>
