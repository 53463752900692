<template>
  <div class="nuxt-layout-wrapper">
    <div class="layout-header-wrapper">
      <AppHeader></AppHeader>
    </div>
    <div class="layout-main-wrapper">
      <NuxtPage />
    </div>
    <div class="layout-bottom-wrapper">
      <div class="layout-bottom-inner-wp">
        <AppFooter></AppFooter>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.nuxt-layout-wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  .layout-header-wrapper {
    width: 100%;
    flex-shrink: 0;
    height: 74px;
  }
  .layout-main-wrapper {
    width: 100%;
    flex: 1;
    height: auto;
  }
  .layout-bottom-wrapper {
    width: 100%;
    flex-shrink: 0;
  }
}
</style>
