<template>
  <div class="app-header-wrapper">
    <div class="header-inner-wrapper">
      <div class="header-left-wrapper">
        <a class="logo-container" href="/">
          <Logo />
        </a>
        <div class="line-container"></div>
        <div class="nav-item-wrapper">
          <a class="nav-item-container" href="#about-us">About Us</a>
          <a class="nav-item-container" href="#our-services">Our Services</a>
          <a class="nav-item-container" href="#our-advantages"
            >Our Advantages</a
          >
        </div>
      </div>
      <div class="header-right-wrapper">
        <div class="phone-nav-button" @click.stop="toggleHandle">
          <PhoneNavIcon />
        </div>
      </div>
    </div>
    <div class="phone-nav-menu" v-if="showPhoneMenu" @click.self="hidePhoneMenu">
      <div class="menu-list-container">
        <a class="nav-item-row" href="#about-us" @click.stop="hidePhoneMenu">About Us</a>
        <a class="nav-item-row" href="#our-services" @click.stop="hidePhoneMenu">Our Services</a>
        <a class="nav-item-row" href="#our-advantages" @click.stop="hidePhoneMenu">Our Advantages</a>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import Logo from "./icons/Logo.vue";

let showPhoneMenu = ref(false);

const toggleHandle = () => {
  showPhoneMenu.value = !showPhoneMenu.value;
};

const hidePhoneMenu = () => {
  showPhoneMenu.value = false;
}
</script>
<style lang="scss" scoped>
.app-header-wrapper {
  background: #000;
  width: 100%;
  position: fixed;
  z-index: 9999;
  .header-inner-wrapper {
    height: 74px;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1280px) {
      padding-left: 30px;
      padding-right: 30px;
    }
    .header-left-wrapper {
      display: grid;
      grid-auto-flow: column;
      gap: 40px;
      align-items: center;
      .logo-container {
        display: inline-block;
        flex-shrink: 0;
        height: 19px;
        width: 108px;
        overflow: hidden;
        cursor: pointer;
      }
      .line-container {
        width: 1px;
        height: 27px;
        flex-shrink: 0;
        background: rgba(255, 255, 255, 0.3);
      }
      .nav-item-wrapper {
        display: grid;
        grid-auto-flow: column;
        gap: 40px;
        align-items: center;
        @media screen and (max-width: 768px) {
          display: none;
        }
        .nav-item-container {
          display: inline-block;
          font-family: Avenir;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
          height: 28px;
          line-height: 28px;
          text-align: center;
          &:hover {
            color: #398cfe;
          }
        }
      }
    }
    .header-right-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 40px;
      .phone-nav-button {
        display: none;
        cursor: pointer;
        @media screen and (max-width: 768px) {
          display: inline-block;
        }
      }
    }
  }
  .phone-nav-menu {
    position: fixed;
    top: 74px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: background-color 0.5s;
    overflow-y: auto;
    z-index: 9999;
    .menu-list-container {
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      background: #000;
      padding: 20px;
      .nav-item-row {
        display: block;
        height: 34px;
        font-family: Avenir;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
        line-height: 34px;
        text-align: left;
        &:hover {
          color: #398cfe;
        }
      }
    }
  }
}
</style>
