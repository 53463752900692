<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="109"
    height="20"
    viewBox="0 0 109 20"
    fill="none"
  >
    <path
      d="M93.3611 8.26371H105.462V0.496887H108.908V19.5031H105.462V11.7363H93.3611V19.5031H89.915V0.496887H93.3611V8.26371Z"
      fill="#398CFE"
    />
    <path
      d="M86.6281 19.5031H74.1959C73.8778 19.5031 73.5332 19.4677 73.162 19.3971C72.7998 19.3175 72.4375 19.1982 72.0752 19.0392C71.7218 18.8801 71.3816 18.6769 71.0547 18.4295C70.7277 18.1733 70.4361 17.8684 70.1799 17.515C69.9325 17.1527 69.7337 16.7374 69.5835 16.2691C69.4333 15.792 69.3582 15.2574 69.3582 14.6654V5.33459C69.3582 5.01649 69.3935 4.67631 69.4642 4.31403C69.5437 3.94292 69.663 3.58064 69.822 3.22721C69.9811 2.86493 70.1887 2.52033 70.445 2.1934C70.7012 1.86646 71.0061 1.57929 71.3595 1.33189C71.7218 1.07564 72.1371 0.872416 72.6054 0.722205C73.0737 0.571993 73.6038 0.496887 74.1959 0.496887H86.6281V3.94292H74.1959C73.7452 3.94292 73.4006 4.06221 73.162 4.30078C72.9235 4.53935 72.8042 4.89279 72.8042 5.36109V14.6654C72.8042 15.1072 72.9235 15.4518 73.162 15.6992C73.4095 15.9378 73.7541 16.057 74.1959 16.057H86.6281V19.5031Z"
      fill="#398CFE"
    />
    <path
      d="M64.8517 11.7363H53.9702V8.26371H64.8517V11.7363ZM66.4024 19.5031H53.9702C53.4931 19.5031 52.9717 19.4191 52.4062 19.2513C51.8407 19.0834 51.315 18.8095 50.829 18.4295C50.3519 18.0407 49.9498 17.5415 49.6229 16.9318C49.3048 16.3133 49.1458 15.5578 49.1458 14.6654V2.23316C49.1458 1.99459 49.1899 1.76927 49.2783 1.5572C49.3667 1.34514 49.4859 1.15959 49.6361 1.00054C49.7952 0.84149 49.9808 0.717787 50.1928 0.629427C50.4049 0.541067 50.6346 0.496887 50.882 0.496887H66.4024V3.94292H52.5918V14.6654C52.5918 15.116 52.7111 15.4606 52.9496 15.6992C53.1882 15.9378 53.5372 16.057 53.9967 16.057H66.4024V19.5031Z"
      fill="#398CFE"
    />
    <path
      d="M46.6806 3.94292H39.086V19.5031H35.64V3.94292H28.0322V0.496887H46.6806V3.94292Z"
      fill="#398CFE"
    />
    <path
      d="M25.4744 19.5031H22.0283V0.496887H25.4744V19.5031Z"
      fill="#398CFE"
    />
    <path
      d="M18.9929 15.3281C18.9929 15.7522 18.9178 16.2073 18.7676 16.6932C18.6262 17.1792 18.3877 17.6299 18.0519 18.0451C17.725 18.4604 17.2964 18.8095 16.7663 19.0922C16.2361 19.3661 15.5867 19.5031 14.8179 19.5031H1.73627C1.48886 19.5031 1.25913 19.4589 1.04706 19.3705C0.835 19.2822 0.649445 19.1629 0.490397 19.0127C0.340185 18.8536 0.2209 18.6681 0.13254 18.456C0.0441799 18.244 0 18.0142 0 17.7668V2.23316C0 1.99459 0.0441799 1.76927 0.13254 1.5572C0.2209 1.34514 0.340185 1.15959 0.490397 1.00054C0.649445 0.84149 0.835 0.717787 1.04706 0.629427C1.25913 0.541067 1.48886 0.496887 1.73627 0.496887H13.2672C13.6914 0.496887 14.1464 0.571993 14.6324 0.722205C15.1184 0.872416 15.569 1.11541 15.9843 1.45117C16.4084 1.7781 16.7574 2.20665 17.0314 2.73681C17.3141 3.26697 17.4555 3.91641 17.4555 4.68514V5.33459C17.4555 5.94427 17.3539 6.60255 17.1506 7.30943C16.9474 8.00747 16.6205 8.67017 16.1698 9.29752C16.5586 9.5361 16.9209 9.82327 17.2567 10.159C17.6013 10.4948 17.9017 10.8836 18.1579 11.3254C18.4142 11.7672 18.6174 12.2664 18.7676 12.8231C18.9178 13.3797 18.9929 13.9938 18.9929 14.6654V15.3281ZM15.5469 14.6654C15.5469 14.2147 15.4762 13.8127 15.3348 13.4593C15.1935 13.097 14.9947 12.7877 14.7384 12.5315C14.4822 12.2752 14.1729 12.0809 13.8106 11.9483C13.4484 11.8069 13.0419 11.7363 12.5913 11.7363H4.82445V8.26371H11.0406C11.4912 8.26371 11.8976 8.19744 12.2599 8.0649C12.6222 7.92353 12.9315 7.72472 13.1877 7.46848C13.4439 7.21223 13.6383 6.90739 13.7709 6.55395C13.9123 6.19168 13.9829 5.78522 13.9829 5.33459V4.68514C13.9829 4.19033 13.7444 3.94292 13.2672 3.94292H3.44603V16.057H14.8179C14.8798 16.057 14.9549 16.0526 15.0433 16.0438C15.1316 16.035 15.2111 16.0085 15.2818 15.9643C15.3525 15.9201 15.4144 15.845 15.4674 15.739C15.5204 15.6329 15.5469 15.4871 15.5469 15.3016V14.6654Z"
      fill="#398CFE"
    />
  </svg>
</template>
